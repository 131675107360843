import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  defaultContext, 
} from '@tanstack/react-query'

import './App.css';
import List from "./components/list";
import TestCycle from './components/test-cycle';
import Form from './components/form';
import TestExecution from './components/test-execution';
import TestExecutionProvider from './context/test-execution/provider';
import TestExecutionContext from './context/test-execution';

const queryClient = new QueryClient({
  context: defaultContext,
})

function App() {
  return (
      <div>
        <QueryClientProvider client={queryClient} context={defaultContext}>
          <HashRouter>
            <Routes>
              <Route
                path=""
                element={<List />} 
              />
              <Route 
                path="/test-cycles/new"
                element={<Form />}
              />
              <Route
                path="/test-cycles/:id"
                element={<TestCycle />}
              />
              <Route 
                path="/test-cycles/:id/edit"
                element={<Form />}
              />
              <Route 
                path="/test-cycles/:testCycleId/test-executions/:id"
                element={
                  <TestExecutionProvider>
                    <TestExecutionContext.Consumer>
                    {
                      () => <TestExecution />
                    }
                    </TestExecutionContext.Consumer>
                  </TestExecutionProvider>
                }
              />
            </Routes>
          </HashRouter>
        </QueryClientProvider>
      </div>
  );
}

export default App;
