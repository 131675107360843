import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useQuery,
} from '@tanstack/react-query'

import { getTestExecution } from '../../services/test-executions';
import TestExecutionContext from './index';
import { getTestCycle } from '../../services/test-cycles';
import { STATUS } from '../../constants';

const TestExecutionProvider = ({children}) => {
    const [selectedTestCaseRun, setCurrentTestCaseRun] = useState(null);

    const { testCycleId, id } = useParams();

    const { isLoading: isLoadingTestExecution, data: testExecution } = useQuery({ 
        queryKey: ['test-execution', id], 
        queryFn: () => {
            return getTestExecution({ id })
        } 
    })

    const { isLoading: isLoadingTestCycle, data: testCycle} = useQuery({
        queryKey: ['test-cycle', testCycleId],
        queryFn: () => {
            return getTestCycle({ id: testCycleId });
        }
    })

    function selectTestCaseRun(id) {
        setCurrentTestCaseRun(id);
    }

    const isLoading = isLoadingTestExecution || isLoadingTestCycle;

    const hasPendingTestCases = testExecution && testExecution.testCases.some(tc => tc.status === STATUS.TEST_CASE_PENDING);

    return (
        <TestExecutionContext.Provider value={{
            selectedTestCaseRun: selectedTestCaseRun && !isLoading ? testExecution.testCases.find(tc => tc._id === selectedTestCaseRun) : null,
            testExecution,
            testCycle,
            isLoading,
            hasPendingTestCases,
            selectTestCaseRun,
        }}>
            {children}
        </TestExecutionContext.Provider>
    )
}

export default TestExecutionProvider;
