/**
 * third party dependencies
 */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultContext } from '@tanstack/react-query'

/**
 * labqube components
 */
import { Message, PageHeader, Table, Button, Input, Loading, LastUpdateBy } from '@labqube/components';

/**
 * services
 */
import {deleteTestCycle, getTestCycles} from '../services/test-cycles';

import './list.css';
import { createTestExecution } from '../services/test-executions';

const List = () => {
    const [testCycles, setTestCycles] = useState([]);
    const [filteredTestCycles, setFilteredTestCycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const [filter, setFilter] = useState('');

    const navigate = useNavigate();

    function refresh() {
        getTestCycles().then(testCycles => {
            setTestCycles(testCycles);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setErrorState(true);
        });
    }

    function deleteItem(item) {
        const result = window.confirm('Do you want to delete this test cycle and its contents?');

        if (result) {
            deleteTestCycle(item._id).then(() => {
                refresh();
            }).catch((e) => {
                console.log(e);
                // TODO https://labqube.atlassian.net/browse/COM-4
            });
        }
    }

    function createExecution(testCycle) {
        return createTestExecution({
            name: 'placeholder',
            testCycleId: testCycle._id,
        }).then(testExecution => {
            navigate(`/test-cycles/${testCycle._id}/test-executions/${testExecution._id}`)
        }).catch((e) => {
            console.log(e);
            // TODO https://labqube.atlassian.net/browse/COM-4
        });
    }

    function goToExecution(testCycle) {
        navigate(`/test-cycles/${testCycle._id}/test-executions/${testCycle.execution._id}`)
    }

    useEffect(() => {
        setFilteredTestCycles(
            testCycles.filter(t => {
                return t.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            }).map(item => {
                return {
                    id: item._id,
                    content: {
                        name: (
                            <Link 
                                to={`/test-cycles/${item._id}`}
                                style={{ color: 'black', wordBreak: 'break-word' }}
                            >
                                {item.name}
                            </Link>
                        ),
                        description: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ item.description || '-' }</span>,
                        'last update by': <LastUpdateBy accountId={item.user_account_id} queryClientContext={defaultContext} />,
                        actions: (
                            <div>
                                { item.execution === undefined && <i className="fa-solid fa-play clickable" onClick={() => createExecution(item)}></i> }
                                { item.execution !== undefined && <i className="fa-solid fa-eye clickable" onClick={() => goToExecution(item)}></i> }
                                &nbsp;
                                <Link to={`/test-cycles/${item._id}/edit`}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                    <i className="fa-solid fa-pen-to-square clickable"></i>
                                </Link>
                                &nbsp;
                                <i className="fa-solid fa-trash clickable" onClick={() => deleteItem(item)}></i>
                            </div>
                        )
                    },
                }
            })
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testCycles, filter]);

    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test cycles...
                </p>
            </Loading>
        )
    }

    if (errorState) {
        return (
            <div style={{ margin: 41 }}>
                <Message
                    title={'Oh no! Something went wrong'}
                    description={'Try reloading the page or contact support if the error persists'}
                />
            </div>
        )
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'Test cycles'}
                style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
                breadcrumbs={[{
                    href: '',
                    text: 'Test cycles'
                }]}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input 
                            style={{ marginRight: 10 }} 
                            placeholder='Search test cycles'
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                        />
                        {
                            testCycles.length !== 0 && (
                                <Link to={`/test-cycles/new`} style={{ width: '100%' }}>
                                    <Button type={'primary'} style={{height: 37}}>
                                        Add test cycle
                                    </Button>
                                </Link>
                            )
                        }
                    </div>
                }
            />
            {
                !filteredTestCycles.length && (
                    <Message
                        title={'No test cycles to show'}
                        description={'Try creating a new one!'}
                        actionComponent={
                            <Link to={`/test-cycles/new`}>
                                <Button type="primary">
                                    Let's start!
                                </Button>
                            </Link>
                        }
                    />
                )
            }
            {
                filteredTestCycles.length !== 0 && (
                    <Table
                        headers={['Name', 'Description', 'Last update by', 'Actions']}
                        columnsWidth={['30%', '30%', '20%', '10%']}
                        items={filteredTestCycles}
                    />
                )
            }
        </div>
    )
}

export default List;
