import React, {useEffect, useState} from 'react';
import Moment from 'react-moment';

import { PageHeader, Button, Description, Table, Loading } from '@labqube/components';
import {getTestCycle} from "../services/test-cycles";

import './test-cycle.css';
import { createTestExecution } from '../services/test-executions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { STATUS } from '../constants';

const TestCycle = () => {
    const [testCycle, setTestCycle] = useState({});
    const [isLoading, setLoading] = useState(true);

    const { id:testCycleId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getTestCycle({ id: testCycleId, allExecutions: true }).then(testCycle => {
            setTestCycle(testCycle);
            console.log(testCycle);
            setLoading(false);
        })
    }, [testCycleId]);

    function createExecution(testCycle) {
        return createTestExecution({
            name: 'placeholder',
            testCycleId: testCycle._id,
        }).then(testExecution => {
            navigate(`/test-cycles/${testCycleId}/test-executions/${testExecution._id}`)
        }).catch((e) => {
            console.log(e);
            // TODO https://labqube.atlassian.net/browse/COM-4
        });
    }

    function goToExecution(testCycle) {
        const runningExecution = testCycle.execution.find(e => e.status === 'running');
        navigate(`/test-cycles/${testCycle._id}/test-executions/${runningExecution?._id}`);
    }

    if (isLoading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test cycle...
                </p>
            </Loading>
        )
    }

    function Executions() {
        if (testCycle?.execution?.length) {
            return (
                <>
                    <Description
                        title="Executions"
                        style={{
                            marginTop: 25,
                            marginBottom: 0,
                        }}
                    />
                    <Table
                        headers={['Date', 'Status', 'Comments']}
                        columnsWidth={['30%', '20%', '50%']}
                        items={testCycle.execution.map(execution => {
                            return {
                                id: execution._id,
                                content: {
                                    date: (
                                        <Link 
                                            to={`/test-cycles/${testCycle._id}/test-executions/${execution._id}`}
                                            style={{ color: 'black', wordBreak: 'break-word' }}
                                        >
                                            <Moment>{execution.createdAt}</Moment>
                                        </Link>
                                        
                                    ),
                                    status: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ execution.status }</span>,
                                    comments: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ execution.comment || '-' }</span>,
                                }
                            }
                        })}
                    />
                </>
            )
        }

        return (
            <>
                <Description
                    title="Executions"
                    content="No test executions yet"
                    style={{
                        marginTop: 25,
                        marginBottom: 0,
                    }}
                />
            </>
        )
    }

    function TestCases() {
        return (
            <>
                <Description
                    title="Associated test cases"
                    style={{
                        marginTop: 25,
                        marginBottom: 0,
                    }}
                />
                <Table
                    headers={['Name', 'Description']}
                    columnsWidth={['30%', '60%']}
                    items={testCycle.testCases.map(testCase => {
                        return {
                            id: testCase.test_case_id_details._id,
                            content: {
                                name: (
                                    <span style={{ color: 'black', wordBreak: 'break-word' }}>
                                        {testCase.test_case_id_details.name}
                                    </span>
                                ),
                                description: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ testCase.test_case_id_details.description || '-' }</span>,
                            }
                        }
                    })}
                />
            </>
        )
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'View Test Cycle'}
                breadcrumbs={[
                    {
                        href: <Link to={'/'}>Test cycles</Link>
                    },
                    {
                        href: '',
                        text: 'View test cycle'
                    }
                ]}
                action={
                    <div>
                        {
                            testCycle.execution.find(e => e.status === STATUS.TEST_EXECUTION_RUNNING) === undefined && 
                            <Button type={'default'} style={{height: 35, marginRight: 5}} onClick={() => createExecution(testCycle)}>
                                Start execution
                            </Button>
                        }
                        {
                            testCycle.execution.find(e => e.status === STATUS.TEST_EXECUTION_RUNNING) !== undefined && 
                            <Button type={'default'} style={{height: 35, marginRight: 5}} onClick={() => goToExecution(testCycle)}>
                                View execution
                            </Button>
                        }
                        <Link to={`/test-cycles/${testCycleId}/edit`}>
                            <Button type={'primary'} style={{height: 35}}>
                                Edit test cycle
                            </Button>
                        </Link>
                    </div>
                }
            />

            <div id="wizard-stage-name">
                <Description title={'Test cycle name'} content={<span style={{ wordBreak: 'break-word' }}>{ testCycle.name }</span>} />
                <Description title={'Description'} content={<span style={{ wordBreak: 'break-word' }}>{ testCycle.description || '-' }</span>} />

                <Executions />
                <TestCases />
            </div>
        </div>
    );
}

export default TestCycle;
