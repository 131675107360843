import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, PageHeader, Loading } from '@labqube/components';

import TestCasesList from './test-cases-list';
import TestRun from './test-run';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TestExecutionContext from '../../context/test-execution';
import { updateTestExecutionStatus } from '../../services/test-executions';
import { STATUS } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';

function TestExecution() {
  const Layout = styled.div`
    display: flex;
  `;

  const Sidebar = styled.div`
    width: 20%;
    margin-right: 5%;
    box-shadow: 1px 0px 0px 0px #ccc;
    padding-right: 20px;
  `;

  const Content = styled.div`
    width: 75%;
  `;

  const { testCycleId } = useParams();
  const { isLoading, testCycle, testExecution, hasPendingTestCases, selectTestCaseRun } = useContext(TestExecutionContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function updateStatus(status, comment) {
    return updateTestExecutionStatus({
      id: testExecution._id,
      status,
      comment,
    }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['test-execution', testExecution._id] });
      navigate(`/test-cycles/${testCycleId}`);
    })
  }

  if (isLoading) {
    return (
        <Loading size='large'>
            <p>
                Loading test execution...
            </p>
        </Loading>
    )
}

  function handleComplete() {
    window.AP.dialog.create({
      key: 'labqube-test-management-comments-dialog',
      width: '50%',
      height: '150px',
      customData: {
          question: 'Would you like to add a comment before completing this test execution?'
      }
    }).on("close", ({ type = 'cancel', comments = '' } = {}) => {
      if (type === 'submit') {
        return updateStatus(STATUS.TEST_EXECUTION_COMPLETED, comments)
          .then(() => {
            navigate(`/test-cycles/${testCycleId}`);
          })
      }
    });
  }

  function Actions() {
    if (testExecution?.status !== STATUS.TEST_EXECUTION_RUNNING) {
      return (
        <Button onClick={() => {
            selectTestCaseRun(null);
        }}>
            See summary
        </Button>
      )
    }

    return (
      <div>
        <Button style={{ marginRight: 5 }} onClick={() => updateStatus(STATUS.TEST_EXECUTION_CANCELLED)}>
          Cancel
        </Button>
        <Button type={hasPendingTestCases ? 'default' : 'primary'}  onClick={() => handleComplete()}>
          Complete
        </Button>
      </div>
    );
  }

  return (
    <div style={{ padding: 41, paddingTop: 0 }}>
      <PageHeader
          title={`Execution of "${testCycle?.name}"`}
          breadcrumbs={[
            {
              href: <Link to={'/'}>Test Cycles</Link>,
            },
            {
              href: <Link to={`/test-cycles/${testCycleId}`}>{testCycle?.name}</Link>,
            },
            {
              href: '',
              text: 'Test execution'
            }
          ]}
          style={{ marginBottom: 30 }}
          action={ <Actions /> }
      />
      <Layout>
        <Sidebar>
          <TestCasesList />
        </Sidebar>
        <Content>
          <TestRun />
        </Content>
      </Layout>
    </div>
  );
}

export default TestExecution;
