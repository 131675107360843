import axios from '../lib/axios';

export function createTestExecution({name, testCycleId}) {
    return axios.post('/api/test-management/test-executions', {
        name,
        test_cycle_id: testCycleId,
    }).then(response => response.data);
}

export function getTestExecutionByTestCycle({ testCycleId, all = false }) {
    return axios.get(`/api/test-management/test-executions?filter_by=${testCycleId}`)
        .then(response => response.data)
        .then(executions => {
            if (all) {
                return executions;
            }
            
            return executions.find(e => e.status === 'running');
        });
}

export function getTestExecution({ id }) {
    return Promise.all([
        axios.get(`/api/test-management/test-executions/${id}`).then(r => r.data),
        axios.get(`/api/test-management/test-executions/${id}/test-executions-test-cases`).then(r => r.data),
    ]).then(([testExecution, testCases]) => {
        return {
            ...testExecution,
            testCases,
        };
    });
}

export function updateTestExecutionTestCaseStatus({ testExecutionId, testCaseId, status }) {
    return axios.put(
        `/api/test-management/test-executions/${testExecutionId}/test-executions-test-cases/${testCaseId}`,
        { status }
    ).then(r => r.data)
}

export function updateTestExecutionStatus({ id, status, comment }) {
    return axios.put(
        `/api/test-management/test-executions/${id}`,
        { status, comment }
    ).then(r => r.data)
}
